import {
    UPDATE_PARTNER,
    UPDATE_PARTNER_FAILED,
    RESET_FORMS
} from '../actions/types'

const initialState = {}

const updatePartnerReducer = (state = initialState, action) => {
    switch(action.type){
        case UPDATE_PARTNER:
            return {
                details: action.payload,
                success: 'Partner updated successfully',
            }
        case UPDATE_PARTNER_FAILED:
            return {
                ...state,
                errors: action.payload,
                success: null
            }
        case RESET_FORMS:
            return initialState
        default:
            return state
    }
}

export default updatePartnerReducer