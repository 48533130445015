import React from 'react'
import Layout from './layout/Layout'
import HeadingSection from './views/HeadingSection'
import banner from '../assets/img/contact-us/contact-us-banner.jpg'
import ScrollToTop from '../ScrollToTop'
import Helmet from 'react-helmet'

class Thankyou extends React.Component {

    render(){

        return(
            <>
            <Helmet>
                <title>Thank You - Contact Us | Lacunza Natural Comfort | South Africa</title>
                <meta name="description" content="Our roots lie in the Sakana valley, in the Navarre region of Spain. We were born and raised here, and our business is marked by more than 50 years of work and a non-stop forward path." />
                {/* Event snippet for Website sale conversion page */}
                <script>{`gtag('event', 'conversion', {'send_to': 'AW-10879165098/63krCIm5g7ADEKrFy8Mo', 'transaction_id': ''});`}</script>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <ScrollToTop />
            <Layout>
                <HeadingSection 
                    banner={banner}
                    heading="Message Sent"
                />
                <section className='_section _section-contact-us'>
                    <div className='_grid'>
                        <div className='_column' style={{textAlign: 'center'}}>
                            <h2>Thank you for contacting us</h2>   
                            <p>A consultant wil be in touch with you shortly.</p> 
                        </div>    
                    </div>    
                </section>
            </Layout>
            </>
        )
    }
}

export default Thankyou