import {
    UPDATE_DOCUMENT,
    UPDATE_DOCUMENT_FAILED,
    RESET_FORMS
} from '../actions/types'

const initialState = {}

const updateDocumentReducer = (state = initialState, action) => {
    switch(action.type){
        case UPDATE_DOCUMENT:
            return {
                details: action.payload,
                success: 'Document updated successfully',
            }
        case UPDATE_DOCUMENT_FAILED:
            return {
                ...state,
                errors: action.payload,
                success: null
            }
        case RESET_FORMS:
            return initialState
        default:
            return state
    }
}

export default updateDocumentReducer