import React from 'react'
import Layout from './layout/Layout'
import HeadingSection from './views/HeadingSection'
import banner from '../assets/img/contact-us/contact-us-banner.jpg'
import ContactForm from './forms/ContactForm'
import ScrollToTop from '../ScrollToTop'
import Helmet from 'react-helmet'
import {connect} from 'react-redux'
import {
    sendContactMessage,
    resetForms,
    fetchPublicProductNames
} from '../actions'
// import history from '../history'

class Contact extends React.Component {

    componentDidMount(){
        this.props.fetchPublicProductNames()
    }

    componentWillUnmount(){
        this.props.resetForms()
    }

    componentDidUpdate(){
        if(this.props.send.errors){
            setTimeout(() => {
                this.props.resetForms()
            }, 7000)
        }
    }

    onSubmit = formValues => {
        this.props.sendContactMessage(formValues)
    }

    render(){

        if(this.props.send.success){
            window.location = '/contact-us/thankyou'
        }
        
        return(
            <>
            <Helmet>
                <title>Contact Us | Lacunza Natural Comfort | South Africa</title>
                <meta name="description" content="Get in touch with a Lacunza SA Specialist today." />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <ScrollToTop />
            <Layout>
                <HeadingSection 
                    banner={banner}
                    heading="CONTACT US"
                />
                <section className='_section _section-contact-us'>
                    <div className='_grid'>
                        <div className='_column' style={{textAlign: 'center'}}>
                            <h2>Get In Touch</h2>   
                            <p>Please fill out the following form if you wish to send us a message with your feedback or commentary.</p> 
                        </div>    
                    </div>    
                    <div className='_grid'>
                        <div className='_column'>
                            <div className='_panel'>
                                <ContactForm
                                    onSubmit={this.onSubmit} 
                                    errors={this.props.send.errors ? this.props.send.errors : null}
                                    cats={this.props.cats ? this.props.cats : null}
                                />   
                            </div>
                        </div>    
                    </div> 
                    <div className='_grid'>
                        <div className='_column'>
                            <h2>Or Email Us At</h2>   
                            <p style={{textAlign: 'center'}}><a href="mailto:info@lacunza.co.za">info@lacunza.co.za</a></p> 
                        </div>    
                    </div> 
                </section>
            </Layout>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        send: state.contactSatate,
        cats: state.publicProductNamesState,
    }
}

export default connect(mapStateToProps, {
    sendContactMessage,
    resetForms,
    fetchPublicProductNames
})(Contact)