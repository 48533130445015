import React from 'react'
import {Link} from 'react-router-dom'

class NavListItems extends React.Component {

    render(){

        return(
            <>
                <li className='_nav-list-item'>
                    <Link to ="/" className="_nav-link">HOME</Link>
                </li>
                <li className='_nav-list-item'>
                    <Link to ="/products" className="_nav-link">PRODUCTS</Link>
                </li>
                <li className='_nav-list-item'>
                    <Link to ="/where-to-buy" className="_nav-link">WHERE TO BUY</Link>
                </li>
                <li className='_nav-list-item'>
                    <Link to ="/become-a-distributor" className="_nav-link">BECOME A DISTRIBUTOR</Link>
                </li>
                
                <li className='_nav-list-item'>
                    <Link to ="/contact-us" className="_nav-link">CONTACT US</Link>
                </li>
            </>
        )
    }
}

export default NavListItems