import React from 'react'
import {Link} from 'react-router-dom'

class Ui extends React.Component {

    render(){

        return(
            <section>
                <div className='_grid'>
                    <div className='_column'>
                        <h1>There’s a LACUNZA<br />that’s right for you</h1>
                        <h3>#NaturalComfort</h3>
                        <h2>
                            <span>Lacunza is…</span><br />
                            HISTORY
                        </h2>
                        <p>Our roots lie in the Sakana valley, in the Navarre region of Spain. We were born and raised here, and our business is marked by more than 50 years of work and a non-stop forward path.</p>
                        <p>For more than half a century, we have forged our business’ unique personality based on the sustainability of the environment, innovation, technology, design and customer orientation.</p>
                        <p>From the original iron foundry and subsequent cookstove and fireplace factory, LACUNZA has undergone a remarkable evolution and development. This is evidenced in our range of products, models, materials and energy sources, as well as the continued incorporation of quality, innovation and design into all our products.</p>
                    </div>
                </div>

                <div className='_grid'>
                    <div className='_group'>
                        <Link to="/" className="_button">SEE MORE</Link>
                        <Link to="/" className="_button _button-1">BUILT-IN</Link>
                        <button className='_button _button-2'>FREESTANDING</button>
                        <Link to="/" className="_button _button-3">CONTACT US</Link>
                    </div>
                </div>

                
                
                <nav className='_nav'>
                    <ul className='_nav-list'>
                        <li className='_nav-list-item'>
                            <Link to ="/" className="_nav-link">HOME</Link>
                        </li>
                        <li className='_nav-list-item'>
                            <Link to ="/" className="_nav-link">PRODUCTS</Link>
                        </li>
                        <li className='_nav-list-item'>
                            <Link to ="/" className="_nav-link">WHERE TO BUY</Link>
                        </li>
                    </ul>
                </nav>
                <footer>
                    <ul className='_nav-list'>
                        <li className='_nav-list-item'>
                            <Link to ="/" className="_nav-link">HOME</Link>
                        </li>
                        <li className='_nav-list-item'>
                            <Link to ="/" className="_nav-link">PRODUCTS</Link>
                        </li>
                        <li className='_nav-list-item'>
                            <Link to ="/" className="_nav-link">WHERE TO BUY</Link>
                        </li>
                    </ul>
                </footer>
            </section>
        )
    }
}

export default Ui