import {
    CREATE_PRODUCT,
    CREATE_PRODUCT_FAILED,
    RESET_FORMS
} from '../actions/types'

const initialState = {}

const createProductReducer = (state = initialState, action) => {
    switch(action.type){
        case CREATE_PRODUCT:
            return {
                details: action.payload,
                success: 'Product created successfully',
            }
        case CREATE_PRODUCT_FAILED:
            return {
                ...state,
                errors: action.payload,
                success: null
            }
        case RESET_FORMS:
            return initialState
        default:
            return state
    }
}

export default createProductReducer