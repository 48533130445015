import React from 'react'
import DashboardLayout from './layout/DashboardLayout'
import {Link, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    fetchCategory,
    resetForms,
    updateCategory
} from '../../actions'
import Form from '../forms/CategoryEditForm'

class DashboardCategoryEdit extends React.Component {

    componentDidMount(){
        this.props.fetchCategory(this.props.token, this.props.match.params.categoryId)
    }

    componentWillUnmount(){
        this.props.resetForms()
    }

    onSubmit = formValues => {
        this.props.updateCategory(this.props.token, this.props.match.params.categoryId, formValues)
    }

    render(){

        if(this.props.data.success){
            return <Redirect to="/dashboard/categories" />
        }

        return(
            <DashboardLayout 
                heading="Edit Category" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / <Link to="/dashboard/categories">Categories</Link> / {this.props.match.params.categoryId} / Edit
                </>)}
            >

                {
                    this.props.initialValues && 
                    <Form 
                        onSubmit={this.onSubmit} 
                        errors={this.props.data.errors ? this.props.data.errors : null} 
                        success={this.props.data.success ? this.props.data.success : null}
                        initialValues={this.props.initialValues}
                    />
                }
                
            </DashboardLayout>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        token: state.authState.token,
        initialValues: state.categoriesState[ownProps.match.params.categoryId],
        data: state.categoryUpdateState
    }
}

export default connect(mapStateToProps, {
    fetchCategory,
    updateCategory,
    resetForms
})(DashboardCategoryEdit)