import React from 'react'
import DashboardLayout from './layout/DashboardLayout'
import {Link} from 'react-router-dom'

class Dashboard extends React.Component {

    render(){
        return(
            <DashboardLayout 
                heading="Dashboard" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link>
                </>)}
            >

                <nav className='_dashboard-primary-navigation'>
                    <ul>
                    <li>
                            <Link to="/dashboard/products" className='_button-2-large'>Products</Link>
                        </li>
                        <li>
                            <Link to="/dashboard/categories" className='_button-2-large'>Categories</Link>
                        </li>
                        <li>
                            <Link to="/dashboard/partners" className='_button-2-large'>Partners</Link>
                        </li>
                    </ul>
                </nav>

            </DashboardLayout>
        )
    }
}

export default Dashboard