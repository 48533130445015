import React from 'react'
import Layout from './layout/Layout'
import HeadingSection from './views/HeadingSection'
import banner from '../assets/img/where-to-buy/where-to-buy-banner.jpg'
import ContactSection from './views/ContactSection'
import ScrollToTop from '../ScrollToTop'
import Helmet from 'react-helmet'
import {
    fetchPublicPartners
} from '../actions'
import {connect} from 'react-redux'

class WhereToBuy extends React.Component {

    state = {
        selectedProvince: null
    }

    componentDidMount(){
        this.props.fetchPublicPartners()
    }

    renderLocations = () => {
        
        return this.props.partners.map(({name,city,province,address,telephone,email,business_hours,map},index) => {
            if(this.state.selectedProvince === '' || this.state.selectedProvince === province || this.state.selectedProvince === null){
                return (
                    <React.Fragment key={index}>
                        {city && (
                            <div className='_grid'>
                                <div className='_column'>
                                    <div className="_group _group-section-subheading"><h2>{city}</h2><div className="_hr" /></div>
                                </div>
                            </div>
                        )}
                        
                        
                        <div className='_grid _grid-2'>
                            <div className='_column'>
                                <div className='_panel'>
                                    {name && (
                                        <h3>{name}</h3>
                                    )}
                                    <div className='_panel-item'>
                                        {address && (
                                            <>
                                                <p><strong>Address</strong></p>
                                                <div dangerouslySetInnerHTML={{__html:address}} />
                                            </>
                                        )}
                                        {telephone && (
                                            <>
                                                <p><strong>Telephone</strong></p>
                                                <div dangerouslySetInnerHTML={{__html:telephone}} />
                                            </>
                                        )}
                                        
                                    </div>
                                    <div className='_panel-item'>
                                        {email && (
                                            <>
                                                <p><strong>Email</strong></p>
                                                <p><a href={`mailto:${email}`}>{email}</a></p>
                                            </>
                                        )}
                                        {business_hours && (
                                            <>
                                                <p><strong>Business Hours</strong></p>
                                                <div dangerouslySetInnerHTML={{__html:business_hours}} />
                                            </>
                                        )}
                                        
                                    </div>
                                </div>
                            </div>
                            <div className='_column' >
                                {map && <div className='_map' dangerouslySetInnerHTML={{__html: map}} />}
                                
                            </div>
                        </div><br /><br /><br /><br /><br />
                    </React.Fragment>
                )
            }
            return <></>
        })
    }

    renderOptions = () => {
        
        const options = [
            'Western Province',
            'Eastern Cape',
            'Free State',
            'Gauteng',
            'KwaZulu-Natal',
            'Limpopo',
            'Mpumalanga',
            'Northern Cape',
            'North West',
        ]

        const newArray = []

        for(let i = 0; i < this.props.partners.length; i++){
            if(options.includes(this.props.partners[i].province) ){
                if(!newArray.includes(this.props.partners[i].province)){
                    newArray[i] = this.props.partners[i].province
                }
            }
        }

        return newArray.map((option, index)=>{
            return <option key={index}>{option}</option>
        })
    }

    filterProvince = e => {
        this.setState({
            selectedProvince: e.target.value
        })
    }

    render(){

        return(
            <>
            <Helmet>
                <title>Where To Buy | Lacunza Natural Comfort | South Africa</title>
                <meta name="description" content="Find a Lacunza SA Distributor near you." />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <ScrollToTop />
            <Layout>
                <HeadingSection 
                    banner={banner}
                    heading="WHERE TO BUY"
                />

                <section className='_section _section-locations'>
                    <div className='_grid'>
                        <div className='_column'>
                            <label>Filter Province</label>
                            <select onChange={this.filterProvince} className="_selectProvince">
                                <option value="">All Provinces</option>
                                {this.renderOptions()}
                            </select>
                        </div>
                    </div>
                    {this.renderLocations()}    
                </section>
                <ContactSection />
            </Layout>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        partners: state.publicPartnersState
    }
}

export default connect(mapStateToProps, {
    fetchPublicPartners
})(WhereToBuy)