import _ from 'lodash'
import {
    FETCH_PARTNERS,
    CREATE_PARTNER,
    FETCH_PARTNER,
    UPDATE_PARTNER,
    DELETE_PARTNER,
    RESTORE_PARTNER
} from '../actions/types'

const initialState = {}

const partnersReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_PARTNERS:
            return {
                ...state, ..._.mapKeys(action.payload, 'id')
            }
        case CREATE_PARTNER:
            return {
                ...state, [action.payload.id]: action.payload
            }
        case FETCH_PARTNER:
            return {
                ...state, [action.payload.id]: action.payload
            }
        case UPDATE_PARTNER:
            return {
                ...state, [action.payload.id]: action.payload
            }   
        case RESTORE_PARTNER:
            return {
                ...state, [action.payload.id]: action.payload
            } 
        case DELETE_PARTNER:
            return _.omit(state, action.payload)
        default:
            return state
    }
}

export default partnersReducer