import React from 'react'
import DashboardLayout from './layout/DashboardLayout'
import {Link, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    resetForms,
    createCategory
} from '../../actions'
import Form from '../forms/CategoryCreateForm'

class DashboardCategoryCreate extends React.Component {

    componentWillUnmount(){
        this.props.resetForms()
    }

    onSubmit = formValues => {
        this.props.createCategory(this.props.token, formValues)
    }

    render(){

        if(this.props.data.success){
            return <Redirect to="/dashboard/categories" />
        }

        return(
            <DashboardLayout 
                heading="Create Category" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / <Link to="/dashboard/categories">Category</Link> / Create
                </>)}
            >

                <Form 
                    onSubmit={this.onSubmit} 
                    errors={this.props.data.errors ? this.props.data.errors : null} 
                    success={this.props.data.success ? this.props.data.success : null}
                />
            </DashboardLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.authState.token,
        data: state.categoryCreateState
    }
}

export default connect(mapStateToProps, {
    createCategory,
    resetForms
})(DashboardCategoryCreate)