import React from 'react'
import {Field, reduxForm} from 'redux-form'
import {
    Input,
    TextArea,
    Select
} from './fields'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class PartnerEditForm extends React.Component {

    state = {
        address: this.props.initialValues.address ? this.props.initialValues.address : "",
        telephone: this.props.initialValues.telephone ? this.props.initialValues.telephone : "",
        business_hours: this.props.initialValues.business_hours ? this.props.initialValues.business_hours : ""
    }

    renderErrors = () => {
        return Object.values(this.props.errors).map((item, index) => {                
            return (
                <div className="_error-group" key={index}>
                    <div className="_error-message">{item[0]}</div>
                </div>
            )
        })
    }

    renderSuccess = () => {
        return (
            <div className="_success-group">
                <div className="_success-message">{this.props.success}</div>
            </div>
        )
    }

    onSubmit = formValues => {
        const data = new FormData()
        formValues.name && data.append('name', formValues.name)
        formValues.city && data.append('city', formValues.city)
        formValues.province && data.append('province', formValues.province)
        this.state.address && data.append('address', this.state.address)
        this.state.telephone && data.append('telephone', this.state.telephone)
        formValues.email && data.append('email', formValues.email)
        this.state.business_hours && data.append('business_hours', this.state.business_hours)
        formValues.map && data.append('map', formValues.map)
        formValues.order_number && data.append('order_number', formValues.order_number)
        this.props.onSubmit(data)
    }

    render(){

        return(
            <form className="_form _form-dashboard" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <div className="_form-row">
                    <Field 
                        type="text"
                        name="name" 
                        label="Name" 
                        component={Input} 
                    />
                </div>

                <div className="_form-row">
                    <Field 
                        type="text"
                        name="city" 
                        label="City" 
                        component={Input} 
                    />
                </div>

                <div className="_form-row">
                    <Field 
                        name="province" 
                        label="Province" 
                        component={Select} 
                    >
                        <option value="">Please select a province</option>
                        <option>Western Province</option>
                        <option>Eastern Cape</option>
                        <option>Free State</option>
                        <option>Gauteng</option>
                        <option>KwaZulu-Natal</option>
                        <option>Limpopo</option>
                        <option>Mpumalanga</option>
                        <option>Northern Cape</option>
                        <option>North West</option>
                    </Field>
                </div>

                <div className="_form-row">
                    <div className="_form-group">
                        <label>Address*</label>
                        <CKEditor
                            editor={ClassicEditor}
                            data={this.state.address}
                            onChange={ ( e, editor ) => {
                                const address = editor.getData();
                                this.setState({ address })
                            } }
                        />
                    </div>
                </div>

                <div className="_form-row">
                    <div className="_form-group">
                        <label>Telephone*</label>
                        <CKEditor
                            editor={ClassicEditor}
                            data={this.state.telephone}
                            onChange={ ( e, editor ) => {
                                const telephone = editor.getData();
                                this.setState({ telephone })
                            } }
                        />
                    </div>
                </div>

                <div className="_form-row">
                    <Field 
                        type="email"
                        name="email" 
                        label="Email" 
                        component={Input} 
                    />
                </div>

                <div className="_form-row">
                    <div className="_form-group">
                        <label>Business Hours*</label>
                        <CKEditor
                            editor={ClassicEditor}
                            data={this.state.business_hours}
                            onChange={ ( e, editor ) => {
                                const business_hours = editor.getData();
                                this.setState({ business_hours })
                            } }
                        />
                    </div>
                </div>

                <div className="_form-row">
                    <Field
                        name="map" 
                        label="Embed Map" 
                        component={TextArea} 
                    />
                </div>

                <div className="_form-row">
                    <Field 
                        type="number"
                        name="order_number" 
                        label="Priority" 
                        component={Input} 
                    />
                </div>
                
                {this.props.errors && this.renderErrors()}
                {this.props.success && this.renderSuccess()}
                <div className="_form-row">
                    <button className="_button _button-2" >Update</button>
                </div>
            </form>
        )
    }
}

const validate = formValues => {
    
    const errors = {}

    if(!formValues.name){
        errors.name = "you must enter a name"
    }

    if(!formValues.city){
        errors.city = "you must enter a city"
    }
    
    return errors
}

export default reduxForm({
    form: 'partneredit',
    validate
})(PartnerEditForm)