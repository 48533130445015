const renderError = ({error, touched}) => {
    if(error && touched){
        return (
            <small className="_error-message">
                {error}
            </small>
        )
    }
}

export const Input = ({input, label, placeholder, meta, type, className}) => {
    return(
        <div className={`_form-group ${className ? className : ''} ${meta.error && meta.touched ? '_error' : ''}`}>
            {label && <label>{label}</label>}
            <input className="_form-element" {...input} type={type} placeholder={placeholder ? placeholder : ""} />
            {renderError(meta)}
        </div>
    )
} 

export const Select = ({input, label, placeholder, meta, children, className}) => {
    return (
        <div className={`_form-group ${className ? className : ''} ${meta.error && meta.touched ? '_error' : ''}`} >
            {label && <label>{label}</label>}
            <select className="_form-element" {...input} placeholder={placeholder ? placeholder : ""}>
                {children}
            </select>
            {renderError(meta)}
        </div>
    )
}

export const TextArea = ({input, label, placeholder, meta, className}) => {
    return (
        <div className={`_form-group  ${className ? className : ''} ${meta.error && meta.touched ? '_error' : ''}`} >
            {label && <label>{label}</label>}
            <textarea className="_form-element" rows="10" {...input} autoComplete="true" placeholder={placeholder ? placeholder : ""} />
            {renderError(meta)}
        </div>
    )
}