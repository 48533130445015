import React from 'react'
import DashboardLayout from './layout/DashboardLayout'
import {Link, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    fetchProduct,
    resetForms,
    updateProduct,
    fetchCategories
} from '../../actions'
import Form from '../forms/ProductEditForm'

class DashboardProductEdit extends React.Component {

    componentDidMount(){
        this.props.fetchProduct(this.props.token, this.props.match.params.productId)
        this.props.fetchCategories(this.props.token)
    }

    componentWillUnmount(){
        this.props.resetForms()
    }

    onSubmit = formValues => {
        this.props.updateProduct(this.props.token, this.props.match.params.productId, formValues)
    }

    render(){

        if(this.props.data.success){
            return <Redirect to="/dashboard/products" />
        }

        return(
            <DashboardLayout 
                heading="Edit Product" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / <Link to="/dashboard/products">Products</Link> / {this.props.match.params.productId} / Edit
                </>)}
            >

                {
                    this.props.initialValues && 
                    <Form 
                        onSubmit={this.onSubmit} 
                        errors={this.props.data.errors ? this.props.data.errors : null} 
                        success={this.props.data.success ? this.props.data.success : null}
                        initialValues={this.props.initialValues}
                        categories={this.props.categories ? this.props.categories : null}
                    />
                }
                
            </DashboardLayout>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        token: state.authState.token,
        initialValues: state.productsState[ownProps.match.params.productId],
        data: state.productUpdateState,
        categories: Object.values(state.categoriesState)
    }
}

export default connect(mapStateToProps, {
    fetchProduct,
    updateProduct,
    resetForms,
    fetchCategories
})(DashboardProductEdit)