import React from 'react'
import DashboardLayout from './layout/DashboardLayout'
import {Link, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    resetForms,
    createImage,
    fetchProducts
} from '../../actions'
import Form from '../forms/ImageCreateForm'

class DashboardImageCreate extends React.Component {

    componentDidMount(){
        this.props.fetchProducts(this.props.token)
    }

    componentWillUnmount(){
        this.props.resetForms()
    }

    onSubmit = formValues => {
        this.props.createImage(this.props.token, this.props.match.params.productId, formValues)
    }

    render(){

        if(this.props.data.success){
            return <Redirect to={`/dashboard/products/${this.props.match.params.productId}/images`} />
        }

        return(
            <DashboardLayout 
                heading="Create Image" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / <Link to={`/dashboard/products`}>Products</Link> / {this.props.match.params.productId} / <Link to={`/dashboard/products/${this.props.match.params.productId}/images`}>Images</Link> / Create
                </>)}
            >

                <Form 
                    onSubmit={this.onSubmit} 
                    errors={this.props.data.errors ? this.props.data.errors : null} 
                    success={this.props.data.success ? this.props.data.success : null}
                    products={this.props.products ? this.props.products : null}
                />
            </DashboardLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.authState.token,
        data: state.imageCreateState,
        products: Object.values(state.productsState)
    }
}

export default connect(mapStateToProps, {
    createImage,
    resetForms,
    fetchProducts
})(DashboardImageCreate)