import React from 'react'
import DashboardLayout from './layout/DashboardLayout'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    fetchDocuments,
    deleteDocument
} from '../../actions'
import DataTable from 'react-data-table-component';
import {BASE_PATH} from '../../paths'

class DashboardDocuments extends React.Component {

    componentDidMount(){
        this.props.fetchDocuments(this.props.token, this.props.match.params.productId)
    }

    onDelete = id => {
        this.props.deleteDocument(this.props.token, this.props.match.params.productId, id)
    }

    renderButtons = id => {
        return (
            <div className="_buttons-group">
                <Link className="_table-button _table-button-edit" to={`/dashboard/products/${this.props.match.params.productId}/documents/${id}/edit`} >Edit</Link>
                <button className="_table-button _table-button-delete" onClick={() => this.onDelete(id)} >Delete</button>
            </div>
        )
    }

    render(){

        const columns = [
            {
                name: 'ID',
                selector: row => row.id,
                sortable: true,
            },
            {
                name: 'Document',
                selector: row => row.path,
                cell: row => {
                    return (
                        <a href={BASE_PATH+row.path} target="_blank" rel='noreferrer'>{BASE_PATH+row.path}</a>
                    )
                }
            },
            {
                name: 'Title',
                selector: row => row.title,
                sortable: true,
            },
            {
                name: 'Priority',
                selector: row => row.order_number,
                sortable: true,
            },
            {
                name: 'Actions',
                selector: row => row.actions,
                cell: row => {
                    return this.renderButtons(row.id)
                }
            },
        ];

        const customStyles = {
            headCells: {    
                style: {    
                    color: '#306191',    
                    fontSize: '12px',
                    fontWeight: '600'    
                },
            },
            rows: {
                highlightOnHoverStyle: {
                    backgroundColor: 'rgb(230, 244, 244)',
                    borderBottomColor: '#FFFFFF',
                    borderRadius: '25px',   
                    outline: '1px solid #FFFFFF',   
                },   
            },    
            pagination: {
                style: {
                    border: 'none',
                },
            },
        };

        return(
            <DashboardLayout 
                heading="Documents" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / <Link to={`/dashboard/products`}>Products</Link> / {this.props.match.params.productId} / <Link to={`/dashboard/products/${this.props.match.params.productId}/documents`}>Documents</Link>
                </>)}
            >
                <div className='_button-group'>
                    <Link to={`/dashboard/products/${this.props.match.params.productId}/documents/create`} className='_button _button-2'>+ Create</Link>
                    <Link to={`/dashboard/products/${this.props.match.params.productId}/documents/bin`} className='_button _button-3'>Bin</Link>
                </div>
                {
                    this.props.data && (
                        <DataTable
                            columns={columns}
                            data={this.props.data}
                            pagination
                            customStyles={customStyles}
                        />
                    )
                }
                
            </DashboardLayout>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        token: state.authState.token,
        data: state.documentsState[ownProps.match.params.productId] && Object.values(state.documentsState[ownProps.match.params.productId])
    }
}

export default connect(mapStateToProps, {
    fetchDocuments,
    deleteDocument
})(DashboardDocuments)