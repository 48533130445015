import React from 'react'
import {Link} from 'react-router-dom'
import img from '../../assets/img/home/contact-us.jpg'

class ContactSection extends React.Component {

    render(){

        return(
            <section className='_section _section-contact' style={{backgroundImage: `url(${img})`}}>
                <div className='_grid'>
                    <div className='_column'>
                        <h2>We’re here to help you</h2>
                        <p>Do you have any doubts? Do you have a technical question or require assistance?</p>
                        <Link to="/contact-us" className='_button _button-2'>CONTACT US</Link>
                    </div>
                </div>
            </section>
        )
    }
}

export default ContactSection