import React from 'react'
import {Link} from 'react-router-dom'
import Nav from './Nav'
import Logo from './Logo'
import fancyShape from '../../assets/img/lacunza-logo-block.svg'
import NavListItems from './NavListItems'
import Footer from './Footer'

class Layout extends React.Component {

    state = {
        headerTransform: false,
        menuOpen: false
    }

    componentDidMount(){
        window.addEventListener('scroll', this.handleTransform, { capture: true })
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', this.handleTransform, { capture: true })
    }

    handleTransform = () => {

        let y = window.scrollY
    
        if(y > 300){
            if(!this.state.headerTransform){
                this.setState({
                    headerTransform: !this.state.headerTransform
                })
            }
        } else {
            if(this.state.headerTransform){
                this.setState({
                    headerTransform: !this.state.headerTransform
                })
            }
        }
    }

    onMenuToggle = () => {
        this.setState({
            menuOpen: !this.state.menuOpen
        })
    }

    render(){

        return(
            <>
                <header className='_header-initial'>
                    <div className='_fancy-logo'>
                        <Link to="/">
                            <img className='_fancy-shape' src={fancyShape} alt="LACUNZA" width="279" height="244" />
                        </Link>
                    </div>
                    <div className='_grid'>
                        <div className='_column'>
                            <Nav 
                                onClick={this.onMenuToggle}
                                menuOpen={this.state.menuOpen}
                            />
                        </div>
                    </div>
                </header>
                <header className={`_header-fixed ${this.state.headerTransform ? '_show' : ''}`}>
                    <div className='_grid'>
                        <div className='_column'>
                            <div className='_group'>
                                <Logo />
                                <Nav 
                                    onClick={this.onMenuToggle}
                                    menuOpen={this.state.menuOpen}
                                />
                            </div>
                        </div>
                    </div>
                </header>
                <nav className={`_flyout-menu ${this.state.menuOpen ? '_show' : ''}`}>
                    <div className='_grid'>
                        <div className='_column'>
                            <ul>
                                <NavListItems />
                            </ul>
                        </div>
                    </div>
                </nav>
                <div className='_page-content'>
                    {this.props.children}
                </div>
                <Footer />
            </>
        )
    }
}

export default Layout