import React from 'react'
import DashboardLayout from './layout/DashboardLayout'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    fetchProducts,
    deleteProduct
} from '../../actions'
import DataTable from 'react-data-table-component';
import {BASE_PATH} from '../../paths'

class DashboardProducts extends React.Component {

    componentDidMount(){
        this.props.fetchProducts(this.props.token)
    }

    onDelete = id => {
        this.props.deleteProduct(this.props.token, id)
    }

    renderButtons = id => {
        return (
            <div className="_buttons-group">
                <Link className="_table-button _table-button-edit" to={`/dashboard/products/${id}/edit`} >Edit</Link>
                <Link className="_table-button _table-button-edit" to={`/dashboard/products/${id}/images`} >Images</Link>
                <Link className="_table-button _table-button-edit" to={`/dashboard/products/${id}/documents`} >Documents</Link>
                <button className="_table-button _table-button-delete" onClick={() => this.onDelete(id)} >Delete</button>
            </div>
        )
    }

    render(){

        const columns = [
            {
                name: 'ID',
                selector: row => row.id,
                sortable: true,
            },
            {
                name: 'Preview Image',
                selector: row => row.preview_image,
                cell: row => {
                    return (
                        <img src={BASE_PATH+row.preview_image} alt="" width="100" style={{margin: '10px 0'}} />
                    )
                }
            },
            {
                name: 'Name',
                selector: row => row.name,
                sortable: true,
            },
            {
                name: 'Slug',
                selector: row => row.slug,
                sortable: true,
            },
            {
                name: 'Category',
                selector: row => row.category ? row.category.name : '',
                sortable: true,
            },
            {
                name: 'Priority',
                selector: row => row.order_number,
                sortable: true,
            },
            {
                name: 'Actions',
                selector: row => row.actions,
                cell: row => {
                    return this.renderButtons(row.id)
                }
            },
        ];

        const customStyles = {
            headCells: {    
                style: {    
                    color: '#306191',    
                    fontSize: '12px',
                    fontWeight: '600'    
                },
            },
            rows: {
                highlightOnHoverStyle: {
                    backgroundColor: 'rgb(230, 244, 244)',
                    borderBottomColor: '#FFFFFF',
                    borderRadius: '25px',   
                    outline: '1px solid #FFFFFF',   
                },   
            },    
            pagination: {
                style: {
                    border: 'none',
                },
            },
        };

        return(
            <DashboardLayout 
                heading="Products" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / <Link to="/dashboard/products">Products</Link>
                </>)}
            >
                <div className='_button-group'>
                    <Link to="/dashboard/products/create" className='_button _button-2'>+ Create</Link>
                    <Link to="/dashboard/products/bin" className='_button _button-3'>Bin</Link>
                </div>
                
                <DataTable
                    columns={columns}
                    data={this.props.data}
                    pagination
                    customStyles={customStyles}
                />
            </DashboardLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.authState.token,
        data: Object.values(state.productsState)
    }
}

export default connect(mapStateToProps, {
    fetchProducts,
    deleteProduct
})(DashboardProducts)