import React from 'react'
import {Link} from 'react-router-dom'
import Logo from './Logo'
import NavListItems from './NavListItems'

import img from '../../assets/img/10-years-limited-warranty@2x.png'
import img1 from '../../assets/img/AEFECC@2x.png'
import img2 from '../../assets/img/bureau-veritas@2x.png'
import img3 from '../../assets/img/european-quality2.png'

import instagram from '../../assets/img/instagram-icon.svg'
import facebook from '../../assets/img/facebook-icon.svg'
import youtube from '../../assets/img/youtube-icon.svg'

class Footer extends React.Component {

    render(){

        return(
            <footer>
                <div className='_grid'>
                    <div className='_column'>
                        <div className='_group'>
                            <div className='_item'>
                                <Logo />
                            </div>
                            <div className='_item'>
                                <ul className='_nav-list'>
                                    <NavListItems />
                                </ul>
                            </div>
                            <div className='_item'>
                                <ul className='_footer-list'>
                                    <li>
                                        <Link to="/privacy-policy">Privacy Policy</Link>
                                    </li>
                                    <li>
                                        <Link to="/legal-notice">Legal Notice</Link>
                                    </li>
                                    <li>
                                        <a href="mailto:info@lacunza.co.za">info@lacunza.co.za</a>
                                    </li>
                                </ul>
                            </div>
                            <div className='_item'>
                                <div className='_image-group'>
                                    <div><img src={img} alt="" width="70" /></div>
                                    <div><img src={img3} alt="" width="70" /></div>
                                    <div><img src={img1} alt="" width="70" /></div>
                                    <div><img src={img2} alt="" width="170" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='_foofter-bottom'>
                    <div className='_grid'>
                        <div className='_column'>
                            <div className='_social-icons'>
                                <a href="https://www.facebook.com/lacunza.sa" target="_blank" rel="noreferrer">
                                    <img src={facebook} alt="" />
                                </a>
                                <a href="https://www.instagram.com/lacunza.sa/" target="_blank" rel="noreferrer">
                                    <img src={instagram} alt="" />
                                </a>
                                <a href="https://www.youtube.com/channel/UCTO38IKUkbu6Ffhhb2p_j2A/featured" target="_blank" rel="noreferrer">
                                    <img src={youtube} alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer