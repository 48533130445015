import {
    SUBMIT_DISTRIBUTOR_FORM,
    SUBMIT_DISTRIBUTOR_FORM_FAILED,
    RESET_FORMS
} from '../actions/types'

const initialState = {}

const distributorReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUBMIT_DISTRIBUTOR_FORM:
            return {
                success: action.payload
            }
        case SUBMIT_DISTRIBUTOR_FORM_FAILED:
            return {
                errors: action.payload
            }
        case RESET_FORMS:
            return initialState
        default:
            return state
    }
}

export default distributorReducer