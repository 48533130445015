import React from 'react'
import {
    Field,
    reduxForm
} from 'redux-form'

import {
    Input,
    TextArea
} from './fields'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import Recaptcha from 'react-recaptcha'

class DistributorForm extends React.Component {

    state= {
        button: 'Send',
        message: '',
        capchaLoaded: null,
        capchaVerified: null
    }

    renderErrors = () => {
        return Object.values(this.props.errors).map((item, index) => {                
            return (
                <div className="_error-group">
                    <div className="_error-message" key={index}>{item[0]}</div>
                </div>
            )
        })
    }

    callback = () => {
        console.log('recapcha has loaded')
        this.setState({
            capchaLoaded: true
        })
    }

    verifyCallback = response => {
        console.log('recapcha has been verified')
        this.setState({
            capchaVerified: true
        })
    }

    onSubmit = formValues => {
        if(this.state.capchaLoaded){
            if(this.state.capchaVerified){

                this.setState({
                    button: 'Sending'
                })

                const data = new FormData()

                if(formValues.full_name){ data.append('full_name', formValues.full_name) }
                if(formValues.business){ data.append('business', formValues.business) }
                if(formValues.email){ data.append('email', formValues.email) }
                if(formValues.website){ data.append('website', formValues.website) }
                if(formValues.phone){ data.append('phone', formValues.phone) }
                if(formValues.location){ data.append('location', formValues.location) }
                data.append('message', this.state.message)
                this.props.onSubmit(data)
            } else {
                console.log('Robot filter denied')
            }
        }
    }

    render(){

        if(this.props.errors){
            if(this.state.button === 'Sending'){
                this.setState({
                    button: 'Send'
                })
            }            
        }

        return(
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <div className="_form-row">
                    <Field name="full_name" type="text" component={Input} label="Name and Surname*" />
                </div>
                <div className="_form-row">
                    <Field name="business" type="text" component={Input} label="Name of business*" />
                    <Field name="email" type="email" component={Input} label="Email*" />
                    
                </div>
                <div className="_form-row">
                    <Field name="website" type="text" component={Input} label="Website*" />
                    <Field name="phone" type="text" component={Input} label="Contact Number*" />
                </div>
                <div className="_form-row">
                    <Field name="location" type="text" component={Input} label="Location*" />
                </div>
                <div className="_form-row">
                    <div className="_form-group">
                        <label>Comments*</label>
                        <CKEditor
                            editor={ClassicEditor}
                            data={this.state.message}
                            onChange={ ( event, editor ) => {
                                const message = editor.getData();
                                this.setState({ message })
                            } }
                        />
                    </div>
                </div>
                <div className="_form-row">
                    <div className="_form-group">
                        <label>Please verify that you are not a robot*</label>
                        <Recaptcha
                            sitekey="6Lc4JoQfAAAAAOTYVNxnXz7K9MirqTSpE3jdMZAz"
                            render="explicit"
                            onloadCallback={this.callback}
                            verifyCallback={this.verifyCallback}
                        />
                    </div>
                </div>
                <div className="_form-group">
                    <button className={`_button _button-2 _button-submit ${this.state.button === 'Sending' ? '_sending' : ''}`}>Submit</button>
                </div>
                {
                    this.props.errors && (
                        <div className="_form-row">
                            {this.renderErrors()}
                        </div>
                    )
                }
            </form>
        )
    }
}

const validate = formValues => {

    const errors = {}

    if(!formValues.full_name){
        errors.full_name = "You must enter your full name"
    }

    if(!formValues.business){
        errors.business = "You must enter name of business"
    }

    if(!formValues.website){
        errors.website = "You must enter name of website"
    }

    if(!formValues.email){
        errors.email = "You must enter your email address"
    }

    if(!formValues.location){
        errors.location = "You must enter your location"
    }

    if(!formValues.phone){
        errors.phone = "You must enter your contact number"
    }

    return errors
}

export default reduxForm({
    form: 'distributor',
    validate
})(DistributorForm)