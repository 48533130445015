import React from 'react'
import DashboardLayout from './layout/DashboardLayout'
import {Link, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    resetForms,
    createProduct,
    fetchCategories
} from '../../actions'
import Form from '../forms/ProductCreateForm'

class DashboardProductCreate extends React.Component {

    componentDidMount(){
        this.props.fetchCategories(this.props.token)
    }

    componentWillUnmount(){
        this.props.resetForms()
    }

    onSubmit = formValues => {
        this.props.createProduct(this.props.token, formValues)
    }

    render(){

        if(this.props.data.success){
            return <Redirect to="/dashboard/products" />
        }

        return(
            <DashboardLayout 
                heading="Create Product" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / <Link to="/dashboard/products">Product</Link> / Create
                </>)}
            >

                <Form 
                    onSubmit={this.onSubmit} 
                    errors={this.props.data.errors ? this.props.data.errors : null} 
                    success={this.props.data.success ? this.props.data.success : null}
                    categories={this.props.categories ? this.props.categories : null}
                />
            </DashboardLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.authState.token,
        data: state.productCreateState,
        categories: Object.values(state.categoriesState)
    }
}

export default connect(mapStateToProps, {
    createProduct,
    resetForms,
    fetchCategories
})(DashboardProductCreate)