import React from 'react'
import {
    Field,
    reduxForm
} from 'redux-form'

import {
    Input,
    Select
} from './fields'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import Recaptcha from 'react-recaptcha'

class ContactForm extends React.Component {

    state= {
        message: '',
        button: 'Send',
        capchaLoaded: null,
        capchaVerified: null
    }

    renderErrors = () => {
        return Object.values(this.props.errors).map((item, index) => {                
            return (
                <div className="_error-group">
                    <div className="_error-message" key={index}>{item[0]}</div>
                </div>
            )
        })
    }

    callback = () => {
        console.log('recapcha has loaded')
        this.setState({
            capchaLoaded: true
        })
    }

    verifyCallback = response => {
        console.log('recapcha has been verified')
        this.setState({
            capchaVerified: true
        })
    }

    renderProductNames = cat => {
        return cat.products.map((item, index) => {
            return <option key={index}>{item.name}</option>
        })
    }

    renderProductsDropdown = () => {
        return this.props.cats.map((cat) => {
            return this.renderProductNames(cat)
        })
    }

    onSubmit = formValues => {
        if(this.state.capchaLoaded){
            if(this.state.capchaVerified){

                this.setState({
                    button: 'Sending'
                })

                const data = new FormData()
                if(formValues.full_name){ data.append('full_name', formValues.full_name) }
                if(formValues.email){ data.append('email', formValues.email) }
                if(formValues.phone){ data.append('phone', formValues.phone) }
                if(formValues.province){ data.append('province', formValues.province) }
                if(formValues.city){ data.append('city', formValues.city) }
                if(formValues.product){data.append('product', formValues.product)}
                data.append('message', this.state.message)
                this.props.onSubmit(data)

            } else {
                console.log('Robot filter denied')
            }
        }
    }

    render(){

        if(this.props.errors){
            if(this.state.button === 'Sending'){
                this.setState({
                    button: 'Send'
                })
            }            
        }

        return(
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <div className="_form-row">
                    <Field name="full_name" type="text" component={Input} label="Name and Surname*" />
                </div>
                <div className="_form-row">
                    <Field name="email" type="email" component={Input} label="Email*" />
                    <Field name="phone" type="text" component={Input} label="Phone*" />
                </div>
                <div className="_form-row">
                    <Field 
                        name="province" 
                        label="Province*" 
                        component={Select} 
                    >
                        <option value="">Please select a province</option>
                        <option>Western Province</option>
                        <option>Eastern Cape</option>
                        <option>Free State</option>
                        <option>Gauteng</option>
                        <option>KwaZulu-Natal</option>
                        <option>Limpopo</option>
                        <option>Mpumalanga</option>
                        <option>Northern Cape</option>
                        <option>North West</option>
                    </Field>
                    <Field 
                        type="text"
                        name="city" 
                        label="City / Town*" 
                        component={Input} 
                    />
                </div>
                <div className="_form-row">
                    <Field 
                        name="product" 
                        label="What product are you interested in?" 
                        component={Select} 
                    >
                        <option value="">Please select a product</option>
                        {this.renderProductsDropdown()}
                    </Field>
                </div>
                <div className="_form-row">
                    <div className="_form-group">
                        <label>Comments*</label>
                        <CKEditor
                            editor={ClassicEditor}
                            data={this.state.message}
                            onChange={ ( event, editor ) => {
                                const message = editor.getData();
                                this.setState({ message })
                            } }
                        />
                    </div>
                </div>
                <div className="_form-row">
                    <div className="_form-group">
                        <label>Please verify that you are not a robot*</label>
                        <Recaptcha
                            sitekey="6Lc4JoQfAAAAAOTYVNxnXz7K9MirqTSpE3jdMZAz"
                            render="explicit"
                            onloadCallback={this.callback}
                            verifyCallback={this.verifyCallback}
                        />
                    </div>
                </div>
                 
                <div className="_form-group">
                    <button className={`_button _button-2 _button-submit ${this.state.button === 'Sending' ? '_sending' : ''}`}>Send Message</button>
                </div>
                {
                    this.props.errors && (
                        <div className="_form-row">
                            {this.renderErrors()}
                        </div>
                    )
                }
            </form>
        )
    }
}

const validate = formValues => {

    const errors = {}

    if(!formValues.full_name){
        errors.full_name = "You must enter your full name"
    }

    if(!formValues.email){
        errors.email = "You must enter your email address"
    }

    if(!formValues.phone){
        errors.phone = "You must enter your phone number"
    }

    if(!formValues.province){
        errors.province = "You must select a province"
    }
    
    if(!formValues.city){
        errors.city = "You must enter a city"
    }

    return errors
}

export default reduxForm({
    form: 'contact',
    validate
})(ContactForm)