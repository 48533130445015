import React from 'react'
import Layout from './layout/Layout'
import HeadingSection from './views/HeadingSection'
import banner from '../assets/img/contact-us/contact-us-banner.jpg'
import ScrollToTop from '../ScrollToTop'
import Helmet from 'react-helmet'
import ContactSection from './views/ContactSection'

class LegalNotice extends React.Component {

    render(){

        return(
            <>
            <Helmet>
                <title>Legal Notice | Lacunza Natural Comfort | South Africa</title>
                <meta name="robots" content="noindex,nofollow" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <ScrollToTop />
            <Layout>
                <HeadingSection 
                    banner={banner}
                    heading="LEGAL NOTICE"
                />
                <section className='_section _section-privacy-policy'>
                    <div className='_grid'>
                        <div className='_column'>
                            <div className='_panel'>
                                <h3>0. OBJECT AND ACCEPTANCE</h3>
                                <p>This legal notice governs the use of the website www.lacunza.net (hereinafter, THE WEBSITE), owned by LACUNZA KALOR GROUP, S.A.L. (hereinafter, THE OWNER OF THE WEBSITE).</p>

                                <p>Navigating the website belonging to the OWNER OF THE WEBSITE confers user status and implies full, unreserved acceptance of each and every provision included in this Legal Notice, which may be subject to modifications.</p>

                                <p>Users undertake to use the website correctly in accordance with the law, good faith, public order, trade practices and this Legal Notice. The user shall be held liable by THE OWNER OF THE WEBSITE or by third parties for any damage that may be caused as a consequence of any form of non-compliance with the aforementioned obligation.</p>

                                <h3>1. IDENTIFICATION AND COMMUNICATIONS</h3>

                                <p>The OWNER OF THE WEBSITE, in compliance with Act 34/2002, of 11 July, on Information Society Services and Electronic Commerce, informs you that:</p>

                                <p>Its business name is: LACUNZA KALOR GROUP, S.A.L.<br />
                                Its Tax Identification code is: A31606932<br />
                                Its registered address is: POLIGONO INDUSTRIAL IBARREA, S/N – 31800 ALSASUA – NAVARRA - SPAIN<br />
                                Registered in the Commercial Register of Navarre. Tome 569. Folio 96. Sheet No. NA11928. Insc. 1</p>

                                <p>Different means of communication are made available to you in order to communicate with the OWNER OF THE WEBSITE, namely:</p>
                                <ul>
                                    <li>Tel: ++948563511</li>
                                    <li>Fax: ++948563505</li>
                                    <li>Email: <a href="mailto:comercial@lacunza.net">comercial@lacunza.net</a></li>
                                </ul>
                                <p>All notifications and communications between users and THE OWNER OF THE WEBSITE shall be deemed effective, for all purposes, when made by post or any of the other means listed above.</p>
                                <h3>2. CONDICIONES DE ACCESO Y UTILIZACIÓN</h3>
                                <p>The website and its services are freely accessible without charge; however, THE OWNER OF THE WEBSITE may subject the use of some of the services offered on its website to the completion of the corresponding form.</p>
                                <p>The user guarantees the authenticity and validity of all data supplied to THE OWNER OF THE WEBSITE and shall bear sole responsibility for any false or inaccurate statements made.</p>
                                <p>The user expressly undertakes to make suitable use of the contents and services provided by the OWNER OF THE WEBSITE, and not to use them, amongst other things, in order to:</p>
                                <ol>
                                    <li>Disseminate criminal, violent, pornographic, racist, xenophobic or offensive contents or contents defending terrorism, or, in general, contrary to the law and public order.</li>
                                    <li>Introduce computer viruses into the network or perform actions likely to alter, spoil, interrupt or cause errors in or damage to the electronic documents, data or physical or logical systems belonging to the OWNER OF THE WEBSITE or third parties, or hinder the access of other users to the website and its services through massive consumption of the computer resources through which THE OWNER OF THE WEBSITE provides its services.</li>
                                    <li>Try to access the email accounts of other users or restricted areas of the computer systems belonging to the OWNER OF THE WEBSITE or third parties, or, in the event, extract information.</li>
                                    <li>Infringe the intellectual or industrial property rights or violate the confidentiality of the information belonging to the OWNER OF THE WEBSITE or third parties.</li>
                                    <li>Impersonate other users, the public administration or third parties.</li>
                                    <li>Reproduce, copy, distribute, make available or in any other way publicly communicate, transform or modify the contents without the authorisation of the owner of the relevant rights, unless legally permissible.</li>
                                </ol>
                                <p>    Collect data for advertising purposes or send advertising of any nature, communications for the purpose of sales or communications of any commercial nature without having previously asked permission to do so and been given consent.</p>
                                <p>All the contents of the website, such as texts, photographs, graphics, images, icons, technology, software, graphic design and source codes are the property of THE OWNER OF THE WEBSITE and it cannot be understood that any rights to use these have in any way been transferred to the user beyond the extent strictly necessary for the correct use of the website.</p>
                                <p>In brief, users who gain access to this website are able to view its contents and make, if appropriate, private, authorised copies provided that the elements reproduced are not subsequently transferred to third parties, installed in servers connected to networks or subjected to any kind of exploitation.</p>
                                <p>Likewise, all the trademarks, commercial names and distinctive markings of all kinds which appear on the website are the property of the OWNER OF THE WEBSITE and it cannot be understood that use of or access to these confers any right over them to the user.</p>
                                <p>The distribution, modification, transfer and public communication of the contents and any other action not expressly authorised by the holder of the rights of use are forbidden.</p>
                                <p>The establishment of a hyperlink does not imply, under any circumstance, the existence of a relationship between THE OWNER OF THE WEBSITE and the owner of the website in which it is established, nor acceptance or approval by THE OWNER OF THE WEBSITE of the latter’s website’s contents or services.</p>
                                <p>Those who wish to establish a hyperlink must previously apply for written authorisation from THE OWNER OF THE WEBSITE. In all events, such a hyperlink may only allow access to the homepage of the website and those establishing hyperlinks must refrain from making false, inexact or incorrect declarations or statements concerning THE OWNER OF THE WEBSITE and from including illegal contents, contrary to good practices and public order.</p>
                                <p>THE OWNER OF THE WEBSITE accepts no responsibility for the use to which each user puts the material made available on this website or any actions performed on the basis of it.</p>

                                <h3>3. EXCLUSION FROM GUARANTEES AND LIABILITY</h3>
                                <p>The contents of this website are of a general nature and merely aim to provide information. No guarantee of access to all contents, the thoroughness, correctness, validity or current accuracy of the contents, or their suitability or utility for specific purposes is given.</p>
                                <p>THE OWNER OF THE WEBSITE excludes itself, to the extent permitted by law, from any liability for damages of any kind deriving from:</p>
                                <ul>
                                    <li>The impossibility of gaining access to the website or any lack of veracity, exactitude, thoroughness and/or current accuracy of the contents, or the existence of errors or defects of any kind in the contents transmitted, disseminated, stored or made available to which access is gained through the website or the services offered.</li>
                                    <li>The presence of viruses or other elements in the contents which might cause disturbances in the computer systems, electronic documents or data of users.</li>
                                    <li>Non-compliance with laws, good faith, public order, trade practices and this legal notice as a consequence of the incorrect use of the website. In particular, and as an example, THE OWNER OF THE WEBSITE does not accept liability for the actions of third parties that may infringe intellectual and industrial property rights, business secrets, rights to honour, the intimacy of persons or families, or to their image, or regulations concerning unfair competition and illicit advertising.</li>
                                </ul>
                                <p>Likewise, THE OWNER OF THE WEBSITE accepts no liability for information found outside this website and which is not managed directly by its Webmaster. The exclusive function of the links that appear in this website is to inform the user about the existence of other sources that may expand on the contents offered on this website. THE OWNER OF THE WEBSITE does not guarantee or accept liability for the operation or accessibility of the linked websites; nor does it suggest, invite or recommend users to visit them, thereby accepting no liability for the results obtained. THE OWNER OF THE WEBSITE accepts no liability for the creation of hyperlinks by third parties.</p>
                            </div>
                        </div>    
                    </div>    
                </section>
                <ContactSection />
            </Layout>
            </>
        )
    }
}

export default LegalNotice