import React from 'react'
import Layout from './layout/Layout'
import HeadingSection from './views/HeadingSection'
import banner from '../assets/img/contact-us/contact-us-banner.jpg'
import ScrollToTop from '../ScrollToTop'
import Helmet from 'react-helmet'
import ContactSection from './views/ContactSection'

class PrivacyPolicy extends React.Component {

    render(){

        return(
            <>
            <Helmet>
                <title>Privacy Policy | Lacunza Natural Comfort | South Africa</title>
                <meta name="robots" content="noindex,nofollow" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <ScrollToTop />
            <Layout>
                <HeadingSection 
                    banner={banner}
                    heading="PRIVACY POLICY"
                />
                <section className='_section _section-privacy-policy'>
                    <div className='_grid'>
                        <div className='_column'>
                            <div className='_panel'>
                                <h3>INTRODUCTION</h3>
                                <p>When we require information from you, we will always expressly ask you to supply it on a voluntary basis. Your data is collected through data-collection forms on the website or by other means. This entity will treat the data in a confidential manner and solely in order to offer the services requested, with all the legal and security guarantees required by REGULATION (EU) 2016/679 OF THE EUROPEAN PARLIAMENT AND OF THE COUNCIL and Act 34/2002, of 11 July, on Information Society Services and Electronic Commerce. </p>
                                <p>(Basic Information Layer I)<br />
                                Basic Information on Data Protection</p>
                                <p>Controller: LACUNZA KALOR GROUP, S.A.L.</p>
                                <p><strong>Purpose: To provide the requested services and send commercial communications and newsletter Legitimacy:</strong></p>
                                <ul>
                                    <li>Performance of a contract.</li>
                                    <li>Consent of the data subject.</li>
                                </ul>
                                <p><strong>Recipients:</strong><br />
                                Data shall not be transferred to third parties, unless legally required.</p>   
                                <p><strong>Rights:</strong><br />
                                You have the right to access, rectify and erase your data, together with other rights, as indicated in the additional information. You may exercise these rights by writing to the data controller at POLIGONO INDUSTRIAL IBARREA, S/N - 31800 - ALSASUA - NAVARRA - SPAIN</p> 
                                <p><strong>Provenance:</strong><br />
                                The data subject.</p>
                                <p><strong>Additional information:</strong></p>
                                <p>Find further, more detailed information on Data Protection on our website.</p>
                                <p>The OWNER OF THE WEBSITE applies the security levels required by REGULATION (EU) 2016/679 OF THE EUROPEAN PARLIAMENT AND OF THE COUNCIL, and other applicable regulations. However, it assumes no liability for any damage caused by alterations made to computer systems, electronic documents or user files by third parties.</p>
                                <p>THE OWNER OF THE WEBSITE may use cookies when providing website services. Cookies are physical personal information files stored on the user’s device. The user can configure his/her browser to prevent the creation of cookie files or to receive a warning when they are created.</p>
                                <p>If you choose to leave the website via links to sites that do not belong to it, THE OWNER OF THE WEBSITE accepts no liability for the privacy policies of such websites or any cookies that they may store on your computer.</p>
                                <p>Our policy on email messages centres on sending only those communications that you have asked to receive.</p>
                                <p>If you would prefer not to receive these email messages, you will be offered the opportunity in the emails to exercise your right of cancellation and no longer receive them, in accordance with the provisions of Title III, Article 22 of Act 34/2002 on Information Society Services and Electronic Commerce.<br />
                                Additional information.(Layer II Privacy Policy)</p>
                                <p><strong>Who is responsible for processing your data?</strong></p>
                                <ul>
                                    <li>Identity: LACUNZA KALOR GROUP, S.A.L. Tax ID: A31606932</li>
                                    <li>Postal address: POLIGONO INDUSTRIAL IBARREA, S/N - 31800 - ALSASUA - NAVARRA - SPAIN</li>
                                    <li>Telephone: ++948563511</li>
                                    <li>Email: comercial@lacunza.net</li>
                                </ul>
                                <p><strong>For what purpose do we process your personal data?</strong><br />
                                LACUNZA KALOR GROUP, S.A.L., the Owner of the Website, processes the information that the data subjects provide in order to handle the administrative, accounting and tax aspects of the services requested and to send its newsletter and commercial communications about its products.</p>
                                <p><strong>How long do we keep your data?</strong><br />
                                Your data will be retained while no request for their erasure is made and, in all events, for as long as required in order to comply with legal obligations.</p>
                                <p><strong>Why is the processing of your personal data legitimate?<br />
                                The legal basis for the processing of your data is:</strong></p>
                                <ul>
                                    <li>Performance of a contract: Provision of the services requested</li>
                                    <li>Consent of the data subject: Sending commercial communications and newsletter</li>
                                </ul>
                                <p><strong>Who will your data be sent to?</strong><br />
                                Data shall not be transferred to third parties, unless legally required.</p>
                                <p><strong>Transfer of data to third countries?</strong><br />
                                The transfer of data to third countries is not envisaged.</p>
                                <p><strong>What are your rights when you provide us with your data?</strong><br />
                                Everyone has the right to obtain confirmation as to whether LACUNZA KALOR GROUP, S.A.L. is processing personal data which concern them or not.</p>
                                <p>Data subjects have the right to access their personal data, as well as to request the correction of inaccurate data, or, where appropriate, request their erasure when, for among other reasons, the data are no longer necessary for the purposes for which they were collected</p>
                                <p>In certain circumstances, data subjects may request to limit the processing of their data, in which case we shall only keep them for the exercise or defence of legal claims.</p>
                                <p>In certain circumstances, and for reasons related to their individual circumstances, data subjects may object to the processing of their data. In this case, LACUNZA KALOR GROUP, S.A.L. shall cease to process the data, save overriding legitimate grounds or to exercise or defend legal claims.</p>
                                <p>You may exercise your rights as follows: You have the right of access to and rectification, erasure and portability of your data, which you can exercise at LACUNZA KALOR GROUP, S.A.L.</p>
                                <p>If you have given your consent for a specific purpose, you have the right to withdraw it at any time, without affecting the legality of any processing performed on the basis of consent prior to its withdrawal.</p>
                                <p>If you feel that your personal data protection rights have been violated, particularly if you are not satisfied with regard the exercise of your rights, you can file a complaint with the competent Data Protection Control Authority via its website: www.agpd.es. </p>
                                <h3>HOW DO WE OBTAIN YOUR DATA?</h3>
                                <p>The personal data we process in LACUNZA KALOR GROUP, S.A.L. come from the data subject.<br />
                                No special personal data categories (data revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership, genetic data, biometric data for the purpose of uniquely identifying a natural person, data concerning health or data concerning a natural person's sex life or sexual orientation) are processed.</p>
                                <h3>PROCEDURE IN THE EVENT OF UNLAWFUL ACTIVITIES</h3>
                                <p>In the event of any user or third party believing that facts or circumstances exist which expose the illegal use of any content and/or any illegal activity on the web pages included in or accessible through the website, he/she should notify THE OWNER OF THE WEBSITE, duly identifying him/herself, specifying the alleged violations and expressly declaring, under his/her own responsibility, that the information provided in the notification is accurate. For any dispute affecting the website belonging to the OWNER OF THE WEBSITE, Spanish law will apply, the Courts and Tribunals of NAVARRE (Spain) having jurisdiction regarding the matter.</p>
                                <h3>PUBLICATIONS</h3>
                                <p>The administrative information provided through this website does not replace the legal publication of laws, regulations, plans, general provisions and acts, which must be formally published in the official gazettes of the public administration, these constituting the only binding instrument with regard to the authenticity and contents thereof. The information made available on this website should be understood as a guide, devoid of legal validity.</p>
                            </div>
                        </div>    
                    </div>    
                </section>
                <ContactSection />
            </Layout>
            </>
        )
    }
}

export default PrivacyPolicy